import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './page/index';
import { initThemes } from '@zenlayer/zen-design/esm/hooks/useThemeSwitch';

initThemes({ themes: ['dark'] });

const container = document.getElementById('root') as HTMLElement;

ReactDOM.createRoot(container).render(React.createElement(App));
